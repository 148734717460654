import React, { ReactElement } from "react"

import { getResponsiveSizesString } from "@app/features/cover/utils"
import { GoogleImageServiceStatusContext } from "@app/features/google-image-service-status/contexts"
import { useGoogleImageSrc } from "@app/features/google-image-service-status/hooks/use-google-image-src"

interface CoverImageSourcesProps {
  cover: string
}

const CoverImageSources = ({ cover }: CoverImageSourcesProps): ReactElement => {
  const { isAvailable, postfix } = React.useContext(GoogleImageServiceStatusContext)

  const coverSizeM = useGoogleImageSrc(getResponsiveSizesString({ cover, size: "m", imgFormat: postfix }), isAvailable)
  const coverSizeL = useGoogleImageSrc(getResponsiveSizesString({ cover, size: "l", imgFormat: postfix }), isAvailable)
  const coverSizeXl = useGoogleImageSrc(
    getResponsiveSizesString({ cover, size: "xl", imgFormat: postfix }),
    isAvailable
  )
  const coverSizeXxl = useGoogleImageSrc(
    getResponsiveSizesString({ cover, size: "xxl", imgFormat: postfix }),
    isAvailable
  )

  return (
    <React.Fragment>
      <source media="(max-width: 768px)" srcSet={coverSizeM}></source>
      <source media="(max-width: 1440px)" srcSet={coverSizeL}></source>
      <source media="(max-width: 1920px)" srcSet={coverSizeXl}></source>
      <source media="(min-width: 1920px)" srcSet={coverSizeXxl}></source>
    </React.Fragment>
  )
}

export { CoverImageSources }
